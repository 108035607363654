/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2024 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2024 MyFonts Inc. */

@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 400;
  src:
    url('webFonts/AvenirNextItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 200;
  src:
    url('webFonts/AvenirNextUltraLightItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextUltraLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 600;
  src:
    url('webFonts/AvenirNextDemi/font.woff2') format('woff2'),
    url('webFonts/AvenirNextDemi/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 300;
  src:
    url('webFonts/AvenirNextLight/font.woff2') format('woff2'),
    url('webFonts/AvenirNextLight/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 600;
  src:
    url('webFonts/AvenirNextDemiItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextDemiItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 700;
  src:
    url('webFonts/AvenirNextBoldItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextHeavy/font.woff2') format('woff2'),
    url('webFonts/AvenirNextHeavy/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextHeavyItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextHeavyItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 500;
  src:
    url('webFonts/AvenirNextMediumItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 700;
  src:
    url('webFonts/AvenirNextBold/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 200;
  src:
    url('webFonts/AvenirNextUltraLight/font.woff2') format('woff2'),
    url('webFonts/AvenirNextUltraLight/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 300;
  src:
    url('webFonts/AvenirNextLightItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  src:
    url('webFonts/AvenirNextRegular/font.woff2') format('woff2'),
    url('webFonts/AvenirNextRegular/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 200;
  src:
    url('webFonts/AvenirNextThinItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 100;
  src:
    url('webFonts/AvenirNextThin/font.woff2') format('woff2'),
    url('webFonts/AvenirNextThin/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  src:
    url('webFonts/AvenirNextMedium/font.woff2') format('woff2'),
    url('webFonts/AvenirNextMedium/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextCondensedHeavy/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedHeavy/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 700;
  src:
    url('webFonts/AvenirNextCondensedBold/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 600;
  src:
    url('webFonts/AvenirNextCondensedDemi/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedDemi/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 600;
  src:
    url('webFonts/AvenirNextCondensedDemiItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedDemiItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextCondensedHeavyItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedHeavyItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 700;
  src:
    url('webFonts/AvenirNextCondensedBoldItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 300;
  src:
    url('webFonts/AvenirNextCondensedItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 500;
  src:
    url('webFonts/AvenirNextCondensedMedium/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedMedium/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 500;
  src:
    url('webFonts/AvenirNextCondensedMediumItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 300;
  src:
    url('webFonts/AvenirNextCondensedLightItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 400;
  src:
    url('webFonts/AvenirNextCondensed/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensed/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 100;
  src:
    url('webFonts/AvenirNextCondensedThin/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedThin/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 200;
  src:
    url('webFonts/AvenirNextCondensedUltraLightItalic/font.woff2')
      format('woff2'),
    url('webFonts/AvenirNextCondensedUltraLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 300;
  src:
    url('webFonts/AvenirNextCondensedLight/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedLight/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 200;
  src:
    url('webFonts/AvenirNextCondensedUltraLight/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedUltraLight/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 100;
  src:
    url('webFonts/AvenirNextCondensedThinItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextCondensedThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextBlack/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBlack/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 800;
  src:
    url('webFonts/AvenirNextExtraBold/font.woff2') format('woff2'),
    url('webFonts/AvenirNextExtraBold/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextBlackCondensed/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBlackCondensed/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 800;
  src:
    url('webFonts/AvenirNextExtraBoldItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextExtraBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextBlackItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBlackItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 800;
  src:
    url('webFonts/AvenirNextExtraBoldCondensedItalic/font.woff2')
      format('woff2'),
    url('webFonts/AvenirNextExtraBoldCondensedItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: italic;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextBlackCondensedItalic/font.woff2') format('woff2'),
    url('webFonts/AvenirNextBlackCondensedItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Avenir Next Condensed';
  font-style: normal;
  font-weight: 900;
  src:
    url('webFonts/AvenirNextExtraBoldCondensed/font.woff2') format('woff2'),
    url('webFonts/AvenirNextExtraBoldCondensed/font.woff') format('woff');
}
